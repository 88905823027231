import BaseGraph from './base_graph_controller'
import { card, breakDownValue } from '../helpers/tooltip_helper'

export default class LeadingIndicatorsLeadTimeGraphController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this

    const sequenceColors = controller.darkMode ? controller.tailwindColors['sequence-dark'] : controller.tailwindColors['sequence-light']

    controller.stageData = {
      Develop: {
        description: 'Commit to open',
        color: sequenceColors[1]
      },
      Review: {
        description: 'Open to first review',
        color: sequenceColors[2]
      },
      Approve: {
        description: 'First review to first approve',
        color: sequenceColors[3]
      },
      Merge: {
        description: 'First approve to merge',
        color: sequenceColors[4]
      },
      Deploy: {
        description: 'Merge to deploy',
        color: sequenceColors[5]
      }
    }
    let max = 0
    const seriesData = controller.parsedGraphData.series.data.map((datum, index) => {
      max = Math.max(max, datum.y)
      const color = controller.stageData[datum.name].color
      return {
        y: datum.y,
        name: datum.name,
        lineColor: color,
        color,
        zIndex: 1,
        marker: {
          enabled: false
        },
        borderRadiusTopLeft: 5,
        borderRadiusTopRight: 5,
        key: datum.key,
        states: {
          hover: { brightness: controller.darkMode ? -0.1 : 0.05 }
        }
      }
    })
    const series = [{
      data: seriesData,
      name: 'Lead Time'
    }]

    const maxSettings = this.getYAxisMaxAndTickAmountSettings(max)
    return {
      chart: {
        type: 'bar',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingTop: 0,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0
      },
      ...this.baseConfigOptions,
      xAxis: {
        categories: controller.parsedGraphData.categories,
        lineWidth: 0,
        gridLineWidth: 0,
        minorTickLength: 0,
        tickLength: 0,
        labels: {
          useHtml: true,
          align: 'left',
          reserveSpace: true,
          style: {
            width: '250px',
            color: controller.darkMode
              ? controller.tailwindColors.gray[300]
              : controller.tailwindColors.gray[600]
          },
          formatter: function () {
            return `
                <div style="font-size: 12px; font-weight: 500; color: ${controller.darkMode ? Highcharts.color(controller.tailwindColors.gray[200]).get('rgba') : Highcharts.color(controller.tailwindColors.gray[900]).get('rgba')}">${this.value}</div>
                <br><div class="text-xs">${controller.stageData[this.value].description}</div>
            `
          }
        }
      },
      yAxis: [
        {
          title: { text: null },
          gridLineColor: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[200],
          labels: {
            enabled: false
          },
          reversedStacks: false,
          lineWidth: 0,
          gridLineWidth: 0,
          minorTickLength: 0,
          tickLength: 0,
          ...maxSettings
        }
      ],
      series,
      plotOptions: {
        bar: {
          minPointLength: 5
        }
      },
      tooltip: {
        ...this.baseToolTipConfig,
        shared: true,
        formatter: function () {
          const breakDownValues = this.points.reverse().map((p) => {
            const color = p.color.pattern ? p.color.pattern.color : p.color
            return breakDownValue({
              name: p.key,
              style: `background-color: ${color};`,
              value: p.y ? controller.daysOrHoursString(p.y) : '-- days',
              type: 'box'
            })
          }).reverse().filter(p => p)

          return card({
            breakDownValues,
            granularity: controller.optionsValue.date_granularity
          })
        }
      }
    }
  }
}
