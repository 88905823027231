export function benchmarkPlotLines (controller) {
  const isOptimal = controller.optionsValue.benchmark_data?.benchmark === 'optimal'
  let plotLines = []
  if (controller.optionsValue.benchmark_data?.value) {
    plotLines = [
      {
        zIndex: 50,
        color: controller.darkMode ? isOptimal ? controller.tailwindColors.teal[500] : controller.tailwindColors.gray[500] : isOptimal ? controller.tailwindColors.teal[600] : controller.tailwindColors.gray[500],
        value: controller.optionsValue.benchmark_data.value,
        width: 2
      }
    ]
  }
  return plotLines
}
