const colors = require('tailwindcss/colors')
const forms = require('@tailwindcss/forms')

/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    './app/components/**/*.html.erb',
    './app/components/**/*.rb',
    './app/views/**/*.html.erb',
    './app/views/**/*.turbo_stream.erb',
    './app/helpers/**/*.rb',
    './app/models/github_team.rb',
    './app/javascript/**/*.js',
    './public/**/*.html'
  ],
  safeList: [
    'w-36',
    'h-36'
  ],
  darkMode: 'class',
  theme: {
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
      black: '#111827',
      white: colors.white,
      blue: {
        50: '#F0F9FF',
        100: '#E1F2FF',
        200: '#BFE2FF',
        300: '#8BCEFE',
        400: '#54B6FD',
        500: '#0896FC',
        600: '#0388E8',
        700: '#0274C5',
        800: '#0265AB',
        900: '#014779'
      },
      orange: {
        50: '#FFF7ED',
        100: '#FFEDD5',
        200: '#FED7AA',
        300: '#FDBA74',
        400: '#FB923C',
        500: '#F97316',
        600: '#EA580C',
        700: '#C2410C',
        800: '#9A3412'
      },
      purple: {
        50: '#F5F3FF',
        100: '#EDE9FE',
        200: '#DDD6FE',
        300: '#C4B5FD',
        400: '#A78BFA',
        500: '#8B5CF6',
        600: '#7C3AED',
        700: '#6D28D9',
        800: '#6D28D9'
      },
      yellow: {
        50: '#FFFBEB',
        100: '#FEF3C7',
        200: '#FDE68A',
        300: '#FCD34D',
        400: '#FBBF24',
        500: '#F59E0B',
        600: '#D97706',
        700: '#B45309',
        800: '#92400E'
      },
      green: {
        50: '#F0FDF4',
        100: '#DCFCE7',
        200: '#BBF7D0',
        300: '#86EFAC',
        400: '#4ADE80',
        500: '#22C55E',
        600: '#16A34A',
        700: '#15803D',
        800: '#166534'
      },
      cyan: {
        50: '#E6F8FB',
        100: '#CBF0F6',
        200: '#ADE7F1',
        300: '#80DEEE',
        400: '#4ED1E5',
        500: '#06B6D4',
        600: '#0891B2',
        800: '#155E75'
      },
      gray: {
        50: '#F9FAFB',
        100: '#F3F4F6',
        200: '#E5E7EB',
        300: '#D1D5DB',
        400: '#9CA3AF',
        500: '#6B7280',
        600: '#4B5563',
        700: '#374151',
        800: '#1F2937',
        900: '#111827'
      },
      red: {
        50: '#FFF1F2',
        100: '#FFE4E6',
        200: '#FECDD3',
        300: '#FDA4AF',
        400: '#FB7185',
        500: '#F43F5E',
        600: '#E11D48',
        700: '#BE123C',
        800: '#9F1239'
      },
      indigo: {
        50: '#EEF2FF',
        100: '#E0E7FF',
        200: '#C7D2FE',
        300: '#A5B4FC',
        400: '#8FA2FE',
        500: '#6366F1',
        600: '#4F46E5',
        800: '#3730A3'
      },
      pink: {
        100: '#FCE7F3',
        200: '#FBCFE8',
        300: '#F9A8D4',
        500: '#EC4899',
        600: '#DB2777',
        800: '#9D174D'
      },
      teal: {
        50: '#E8F8F6',
        100: '#D0F1ED',
        200: '#B9EAE4',
        300: '#8ADCD3',
        400: '#5BCDC1',
        500: '#14B8A6',
        600: '#0D9488',
        700: '#0F766E',
        800: '#115E59'
      },
      'sequence-light': {
        1: '#CBF0F6',
        2: '#BFE2FF',
        3: '#A9CAF9',
        4: '#9FADF7',
        5: '#A385F9'
      },
      'sequence-dark': {
        1: '#ADE7F1',
        2: '#94CFFF',
        3: '#6FA7F4',
        4: '#5E77F2',
        5: '#6634F5'
      },
      'categorical-light': {
        1: '#BFE2FF',
        2: '#C4B5FD',
        3: '#8ADCD3',
        4: '#FED7AA',
        5: '#ADE7F1',
        6: '#8FA2FE',
        7: '#FDA4AF',
        8: '#FDE68A',
        9: '#4ED1E5',
        10: '#6366F1',
        11: '#F472B6',
        12: '#5BCDC1'
      },
      'categorical-dark': {
        1: '#0896FC',
        2: '#A78BFA',
        3: '#14B8A6',
        4: '#FB923C',
        5: '#4ED1E5',
        6: '#6366F1',
        7: '#FB7185',
        8: '#FBBF24',
        9: '#0891B2',
        10: '#A5B4FC',
        11: '#EC4899',
        12: '#8ADCD3'
      }
    },
    extend: {
      spacing: {
        18: '4.5rem'
      },
      aspectRatio: {
        '3/4': '3 / 4'
      },
      screens: {
        '3xl': '1792px',
        '4xl': '2048px',
        '5xl': '2304px'
      }
    },
    zIndex: {
      0: 0,
      10: 10,
      20: 20,
      25: 25,
      30: 30,
      40: 40,
      50: 50,
      75: 75,
      100: 100,
      1000: 1000,
      max: 2147483648,
      auto: 'auto'
    }
  },
  variants: {
    extend: {
      backgroundOpacity: ['active']
    }
  },
  plugins: [forms]
}
