export function benchmarkPlotBands (controller) {
  let plotBands = []
  if (controller.optionsValue.benchmark_data?.value && Array.isArray(controller.optionsValue.benchmark_data.value)) {
    plotBands = [
      {
        zIndex: 50,
        borderColor: controller.darkMode ? controller.tailwindColors.teal[700] : controller.tailwindColors.teal[200],
        borderWidth: 0.5,
        color: controller.darkMode ? Highcharts.color(controller.tailwindColors.teal[700]).setOpacity(0.25).get('rgba') : Highcharts.color(controller.tailwindColors.teal[100]).setOpacity(0.25).get('rgba'),
        from: controller.optionsValue.benchmark_data.value[0],
        to: controller.optionsValue.benchmark_data.value[controller.optionsValue.benchmark_data.value.length - 1]
      }
    ]
  }
  return plotBands
}
