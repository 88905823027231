import { Controller } from '@hotwired/stimulus'
import { setUrlParams } from '../helpers/url_helper'

// Connects to data-controller="persisted-tabs"
export default class extends Controller {
  static targets = ['tab', 'tabView', 'selectedTabLabel']
  static values = {
    selectedTab: String,
    urlParam: String
  }

  static classes = ['activeTab', 'inactiveTab']

  initialize () {
    // check dataset tabId for each tabView
    this.tabIdToTabView = {}
  }

  tabTargetConnected (tab) {
    if (tab.dataset.tabId === this.selectedTabValue) {
      tab.classList.remove(...this.inactiveTabClasses)
      tab.classList.add(...this.activeTabClasses)
    } else {
      tab.classList.remove(...this.activeTabClasses)
      tab.classList.add(...this.inactiveTabClasses)
    }
  }

  tabViewTargetConnected (tabView) {
    const tabId = tabView.dataset.tabId
    tabView.classList.toggle('hidden', tabId !== this.selectedTabValue)
    this.tabIdToTabView[tabId] = tabView
  }

  selectTab (event) {
    // deactivate all tabs
    this.tabTargets.forEach(tab => {
      tab.classList.remove(...this.activeTabClasses)
      tab.classList.add(...this.inactiveTabClasses)
    })
    // activate the clicked tab
    event.target.classList.remove(...this.inactiveTabClasses)
    event.target.classList.add(...this.activeTabClasses)

    // show the tab view that corresponds to the clicked tab
    this.selectedTabValue = event.target.dataset.tabId

    // hide all tab views
    this.tabViewTargets?.forEach(tabView => { tabView.classList.add('hidden') })

    // show the tab view that corresponds to the selected tab
    this.tabIdToTabView[this.selectedTabValue]?.classList?.remove('hidden')

    // update the url
    setUrlParams(this.urlParamValue, this.selectedTabValue)

    // update tab label if it exists
    if (this.hasSelectedTabLabelTarget) {
      this.selectedTabLabelTargets.forEach(labelTarget => {
        labelTarget.innerHTML = event.target.dataset.tabLabel
      })
    }
  }
}
