import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    searchParams: String,
    peerFormId: String
  }

  connect () {
    if (this.hasSearchParamsValue) {
      this.updateUrl()
    }
  }

  updateUrl () {
    // this will reset a peer form to ensure back-button interactions work
    if (this.hasPeerFormIdValue) {
      const form = document.getElementById(this.peerFormIdValue)
      if (form) {
        form.reset()
      }
    }
    let params = {}
    try {
      params = JSON.parse(this.searchParamsValue)
    } catch (_e) {
      return
    }
    const searchParams = new URLSearchParams()
    for (const key in params) {
      if (params[key]) {
        if (Array.isArray(params[key])) {
          params[key].forEach(value => {
            searchParams.append(key + '[]', value)
          })
        } else {
          searchParams.set(key, params[key])
        }
      }
    }

    const url = new URL(window.location.href)
    const updatedUrl = [
      url.origin,
      url.pathname,
      '?',
      searchParams
    ].join('')
    history.replaceState({}, null, updatedUrl)
  }
}
