import BaseGraph from './base_graph_controller'
import { card, breakDownValue } from '../helpers/tooltip_helper'
import { linearGradientConfig } from '../helpers/fill_color_helper'
import { currentPeriodPlotBand } from '../helpers/current_period_plot_band_helper'
import { benchmarkPlotLines } from '../helpers/benchmark_plot_line_helper'
import { benchmarkPlotBands } from '../helpers/benchmark_plot_band_helper'
export default class LeadingIndicatorsPrDaysGraphController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this

    let max = this.parsedGraphData.max
    const zoneOneLimit = this.parsedGraphData.series_data.length > 1 ? this.parsedGraphData.series_data.length - 2 : 0
    let zones = []
    if (this.parsedGraphData.partial_end_data) {
      zones = [{
        value: zoneOneLimit,
        ...linearGradientConfig(controller)
      }, {
        dashStyle: 'ShortDash',
        ...linearGradientConfig(controller)
      }]
    } else {
      zones = [{
        ...linearGradientConfig(controller)
      }]
    }

    const maxSettings = this.getYAxisMaxAndTickAmountSettings(max)
    if (controller.optionsValue.benchmark_data?.value && controller.optionsValue.benchmark_data.value > max) {
      max = controller.optionsValue.benchmark_data.value
      maxSettings.max = max + (max * 0.1)
    }
    if (controller.parsedGraphData.unit_value === '%') {
      maxSettings.max = 100
      maxSettings.min = 0
      maxSettings.endOnTick = true
    }

    return {
      chart: {
        sync: true,
        type: 'areaspline',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingTop: 0,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        plotBorderWidth: 0
      },
      ...this.baseConfigOptions,
      xAxis: {
        type: 'category',
        categories: controller.parsedGraphData.categories,
        title: { text: null },
        tickLength: 0,
        labels: {
          enabled: true,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        lineWidth: 0,
        plotBands: this.parsedGraphData.series_data.length > 1 && currentPeriodPlotBand(controller, controller.parsedGraphData.comparison_index)
      },
      yAxis: {
        plotBands: benchmarkPlotBands(controller),
        gridLineColor: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[200],
        title: {
          text: '',
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        labels: {
          enabled: true,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          },
          formatter: function () {
            if (this.value !== 0 && max === 0) {
              return '--'
            } else if (controller.parsedGraphData.unit_value) {
              return `${this.value}${controller.parsedGraphData.unit_value}`
            } else {
              return this.value
            }
          }
        },
        startOnTick: false,
        plotLines: benchmarkPlotLines(controller),
        ...maxSettings
      },
      plotOptions: {
        series: {
          trackByArea: true,
          connectNulls: true,
          cursor: 'pointer'
        }
      },
      tooltip: {
        ...this.baseToolTipConfig,
        shared: true,
        formatter: function () {
          const breakDownValues = [
            breakDownValue({
              name: controller.parsedGraphData.name,
              value: controller.parsedGraphData.formatted_data[this.point.index],
              style: `background-color: ${this.color};`,
              type: 'line'
            })
          ]
          const isOptimal = controller.optionsValue.benchmark_data?.benchmark === 'optimal'
          const darkModeColor = isOptimal ? controller.tailwindColors.teal[500] : controller.tailwindColors.gray[500]
          const lightModeColor = isOptimal ? controller.tailwindColors.teal[600] : controller.tailwindColors.gray[500]
          return card({
            date: this.key,
            breakDownValues: [
              ...breakDownValues,
              controller.optionsValue.benchmark_data?.value && breakDownValue({
                name: controller.optionsValue.benchmark_data.legend_name,
                value: controller.optionsValue.benchmark_data.formatted_value,
                style: `background-color: ${controller.darkMode ? darkModeColor : lightModeColor};`,
                type: 'line'
              })
            ]
          })
        },
        style: {
          zIndex: 100
        }
      },
      series: [
        {
          data: controller.parsedGraphData.series_data,
          marker: {
            enabled: false
          },
          lineColor: controller.darkMode ? controller.tailwindColors.gray[400] : controller.tailwindColors.gray[500],
          color: controller.darkMode ? controller.tailwindColors.gray[400] : controller.tailwindColors.gray[500],
          type: 'areaspline',
          name: this.parsedGraphData.name,
          zoneAxis: 'x',
          zones
        }
      ]
    }
  }
}
