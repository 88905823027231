import BaseGraph from './base_graph_controller'
import { card, breakDownValue } from '../helpers/tooltip_helper'
import { fetchSelectedGraphPeriod } from '../helpers/kpi_graph_helper'
import { benchmarkPlotLines } from '../helpers/benchmark_plot_line_helper'

export default class KpiReportFeaturesDeliveredGraphController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this
    const seriesKeys = [
      {
        key: 'features_per_engineer',
        name: 'New Deliveries / Developer',
        lineColor: controller.tailwindColors.blue[500],
        color: controller.tailwindColors.blue[500],
        type: 'areaspline',
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [
              0,
              Highcharts.color(controller.tailwindColors.blue[500])
                .setOpacity(this.darkMode ? 0.3 : 0.2)
                .get('rgba')
            ],
            [
              1,
              Highcharts.color(controller.tailwindColors.blue[500])
                .setOpacity(0)
                .get('rgba')
            ]
          ]
        },
        zoneAxis: 'x'
      }
    ]

    controller.tooltipValues = {}
    controller.parsedGraphData.categories.forEach((category, i) => {
      controller.tooltipValues[category] = { ...controller.parsedGraphData.data[i] }
    })

    let fpeMax = 0
    let seriesConfig = {}

    const series = seriesKeys
      .map((config, i) => {
        const seriesData = controller.parsedGraphData.data.map((data) => data.series[config.key])
        const seriesTotal = seriesData.length ? seriesData.reduce((acc, curr) => acc + curr, 0) : 0

        fpeMax = seriesTotal
        let zones = []
        if (this.parsedGraphData.partial_end_data) {
          zones = [{
            value: seriesData.length > 1 ? seriesData.length - 2 : 0,
            color: controller.tailwindColors.blue[500]
          }, {
            dashStyle: 'ShortDash',
            color: controller.tailwindColors.blue[500]
          }]
        }
        seriesConfig = {
          zones,
          zoneAxis: 'x'
        }

        return {
          data: seriesData,
          lineColor: config.color,
          zIndex: 1,
          marker: {
            enabled: false
          },
          borderRadiusTopLeft: 0,
          borderRadiusTopRight: 0,
          ...seriesConfig,
          ...config
        }
      })

    const fpeMaxSettings = this.getYAxisMaxAndTickAmountSettings(fpeMax)
    return {
      chart: {
        type: 'spline',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingTop: 30,
        spacingRight: 3,
        spacingBottom: 0,
        spacingLeft: 0
      },
      ...this.baseConfigOptions,
      xAxis: {
        categories: controller.parsedGraphData.categories,
        title: { text: null },
        tickLength: 0,
        labels: {
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        gridLineColor: 'transparent',
        lineColor: controller.darkMode
          ? controller.tailwindColors.gray[600]
          : controller.tailwindColors.gray[300],
        minPadding: 0,
        maxPadding: 0
      },
      yAxis: {
        title: { text: null },
        gridLineWidth: 1,
        gridLineColor: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[200],
        labels: {
          enabled: true,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          },
          formatter: function () {
            if (this.value !== 0 && fpeMax === 0) {
              return '--'
            } else {
              return this.value
            }
          }
        },
        plotLines: benchmarkPlotLines(controller),
        reversedStacks: false,
        opposite: false,
        ...fpeMaxSettings
      },
      plotOptions: {
        column: {
          pointPadding: 0.025,
          groupPadding: 0.1,
          borderRadiusTopLeft: 5,
          borderRadiusTopRight: 5
        },
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: false
          },
          stacking: 'normal',
          cursor: 'pointer',
          point: {
            events: {
              click: function () {
                fetchSelectedGraphPeriod({
                  selectedIndex: this.index,
                  parsedGraphData: controller.parsedGraphData,
                  graphUrl: controller.optionsValue.url
                })
              }
            }
          }
        }
      },
      series,
      tooltip: {
        ...this.baseToolTipConfig,
        shared: true,
        formatter: function () {
          const breakDownValues = this.points.reverse().map((p) => {
            const color = p.color.pattern ? p.color.pattern.color : p.color
            if (p.series.name === 'No data') {
              return null
            }
            const featureCount = Math.round(p.y * 10) / 10
            return breakDownValue({
              name: p.series.name,
              style: `background-color: ${color};`,
              value: featureCount,
              type: 'line'
            })
          })

          return card({
            date: controller.tooltipValues[this.key].series.date_range_display,
            breakDownValues: [
              ...breakDownValues,
              controller.optionsValue.benchmark_data.value && breakDownValue({
                name: controller.optionsValue.benchmark_data.legend_name,
                value: controller.optionsValue.benchmark_data.value,
                style: `background-color: ${controller.darkMode ? controller.tailwindColors.teal[500] : controller.tailwindColors.teal[600]}`,
                type: 'line'
              })
            ],
            incomplete: controller.parsedGraphData.partial_end_data && this.points[0].point.index === controller.parsedGraphData.categories.length - 1,
            granularity: controller.optionsValue.date_granularity
          })
        }
      }
    }
  }
}
