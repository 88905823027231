import { percentChangeDiv } from '../helpers/tooltip_helper'
import BaseGraph from './base_graph_controller'

export default class LeadingIndicatorsPullRequestrsBystageGraphController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this

    let max = 0
    const series = controller.parsedGraphData.series.map((datum) => {
      const data = datum.data
      // Math.max can handle nulls
      max = Math.max(max, Math.max(...data))
      const color = datum.key === 'in_progress' ? controller.categoricalColors[2] : controller.categoricalColors[1]
      return {
        data: data,
        lineColor: color,
        color,
        zIndex: 1,
        marker: {
          enabled: false
        },
        minPointLength: datum.key === 'in_progress' ? 0 : 5,
        borderRadiusTopLeft: datum.key === 'in_progress' ? 5 : 0,
        borderRadiusTopRight: datum.key === 'in_progress' ? 5 : 0,
        key: datum.key,
        name: datum.name,
        type: 'column',
        yAxis: 0,
        stacking: 'normal',
        states: {
          hover: { brightness: controller.darkMode ? -0.1 : 0.05 }
        }
      }
    })

    const maxSettings = this.getYAxisMaxAndTickAmountSettings(max)
    return {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingTop: 10,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0
      },
      ...this.baseConfigOptions,
      xAxis: {
        categories: controller.parsedGraphData.categories,
        title: { text: null },
        tickLength: 0,
        useHTML: true,
        labels: {
          formatter: function () {
            if (this.value === controller.optionsValue.max_in_progress_stage) {
              return `<span style="font-size: 12px; font-weight: 500; color: ${controller.darkMode ? Highcharts.color(controller.tailwindColors.red[500]).get('rgba') : Highcharts.color(controller.tailwindColors.red[600]).get('rgba')}">${this.value}</span>`
            } else {
              return `<span style="font-size: 12px; font-weight: 500; color: ${controller.darkMode ? Highcharts.color(controller.tailwindColors.gray[200]).get('rgba') : Highcharts.color(controller.tailwindColors.gray[900]).get('rgba')}">${this.value}</span>`
            }
          }
        },
        gridLineColor: 'transparent',
        lineColor: controller.darkMode
          ? controller.tailwindColors.gray[600]
          : controller.tailwindColors.gray[300],
        minPadding: 0,
        maxPadding: 0
      },
      yAxis: [
        {
          title: { text: null },
          gridLineColor: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[200],
          labels: {
            enabled: true,
            style: {
              color: controller.darkMode
                ? controller.tailwindColors.gray[400]
                : controller.tailwindColors.gray[500]
            },
            formatter: function () {
              if (this.value !== 0 && max === 0) {
                return '--'
              } else {
                return this.value
              }
            }
          },
          reversedStacks: false,
          ...maxSettings
        }
      ],
      plotOptions: {
        column: {
          pointPadding: 0.025,
          groupPadding: 0.1
        },
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: false
          },
          stacking: 'normal',
          cursor: 'pointer',
          point: {
            events: {
              click: function () {
                if (controller.optionsValue.metrics_url) {
                  controller.openMetricDrilldownWithParam('category', this.category)
                }
              }
            }
          }
        }
      },
      series,
      tooltip: {
        ...this.baseToolTipConfig,
        shared: true,
        formatter: function () {
          const previousChangeData = controller.parsedGraphData.percent_change_data[this.x]
          let previousChangeStat = ''
          if (previousChangeData) {
            const changeDiv = percentChangeDiv(previousChangeData.percent_change, previousChangeData.is_lower_better)
            previousChangeStat = `<div class="flex gap-2 items-center">${changeDiv}<span class="text-gray-500 dark:text-gray-300">vs. prev stage</span></div>`
          }
          let inProgressValueDiv = ''

          if (this.x !== 'Develop' && this.points.length > 1) {
            const inProgressValue = this.points[1].y
            const inProgressDisplayValue = inProgressValue === 1 ? `${inProgressValue} PR` : `${inProgressValue} PRs`
            inProgressValueDiv = `
              <div class="flex items-center">
                <div class="w-5">
                  <div class="h-3 w-3 shrink-0 rounded-sm" style="background-color: ${this.points[1].color};"></div>
                </div>
                <p class="text-gray-500 dark:text-gray-300">In progress</p>
              </div>
              <div class="pl-5">
                <p class="text-black dark:text-white font-semibold dd-privacy-mask">${inProgressDisplayValue}</p>
              </div>
            `
          }
          const completedValue = this.points[0].y
          const completedDisplayValue = completedValue === 1 ? `${completedValue} PR` : `${completedValue} PRs`
          return controller.toolTipWrapper(`
            <div class="space-y-2 p-1 w-60">
              <div class="flex flex-col whitespace-normal text-sm">
                <p class="text-gray-500 dark:text-gray-300 font-medium dd-privacy-mask pb-2">${this.x}</p>
                <div class="flex items-center">
                  <div class="w-5">
                    <div class="h-3 w-3 shrink-0 rounded-sm" style="background-color: ${this.points[0].color};"></div>
                  </div>
                  <p class="text-gray-500 dark:text-gray-300">Completed</p>
                </div>
                <div class="pl-5 pb-2 space-y-1">
                  <p class="text-black dark:text-white font-semibold dd-privacy-mask">${completedDisplayValue} / ${controller.optionsValue.date_granularity}</p>
                  ${previousChangeStat}
                </div>
                ${inProgressValueDiv}
              </div>
            </div>
          `)
        }
      }
    }
  }
}
