import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="notification-channel-load-channels"
export default class extends Controller {
  static targets = [
    'channelSelect'
  ]

  static values = {
    orgSlug: String
  }

  updateChannelSrc (event) {
    const connectionSGID = event.target.value
    if (connectionSGID?.length) {
      this.showLoadingState()
      this.channelSelectTarget.src = `/organizations/${this.orgSlugValue}/notification_connections/${connectionSGID}/channels`
    }
  }

  showLoadingState () {
    this.channelSelectTarget.innerHTML = '<select class="min-w-56" disabled><option>Loading...</option></select>'
  }
}
