import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  // requires data-tab-name attribute to be set on the tab elements
  static classes = ['activeTab', 'inactiveTab']
  static targets = ['tab', 'content']
  static values = {
    tabName: { type: String, default: '' }
  }

  tabTargetConnected (tab) {
    if (tab.dataset.tabName === this.tabNameValue) {
      tab.classList.remove(...this.inactiveTabClasses)
      tab.classList.add(...this.activeTabClasses)
    } else {
      tab.classList.remove(...this.activeTabClasses)
      tab.classList.add(...this.inactiveTabClasses)
    }
  }

  selectTab (event) {
    event.preventDefault()

    const currentTabName = event.currentTarget.dataset.tabName
    // update tab styles
    this.tabTargets.forEach(tab => {
      if (tab.dataset.tabName === currentTabName) {
        tab.classList.remove(...this.inactiveTabClasses)
        tab.classList.add(...this.activeTabClasses)
      } else {
        tab.classList.remove(...this.activeTabClasses)
        tab.classList.add(...this.inactiveTabClasses)
      }
    })

    // show the tab view that corresponds to the clicked tab
    this.tabNameValue = currentTabName

    // hide all tab views
    this.contentTargets.forEach(table => { table.classList.add('hidden') })
    for (const content of this.contentTargets) {
      if (content.dataset.tabName === currentTabName) {
        content.classList.remove('hidden')
        break
      }
    }
  }
}
