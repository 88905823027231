import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  submit () {
    this.element.requestSubmit()
  }

  setSingleSelect (event) {
    for (const input of this.element.querySelectorAll('input[type="checkbox"]')) {
      input.checked = false
    }
    for (const input of this.element.querySelectorAll('input[type="radio"]')) {
      input.checked = input.value === event.currentTarget.dataset.singleSelectValue
    }

    this.element.requestSubmit()
  }
}
