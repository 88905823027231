import BaseGraph from './base_graph_controller'
import { card, primaryValue, breakDownValue } from '../helpers/tooltip_helper'

export default class WorkingAgreementGraphController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this
    const colors = [
      Highcharts.color(controller.darkMode ? controller.tailwindColors.red[500] : controller.tailwindColors.red[200]).get('rgba'),
      Highcharts.color(controller.darkMode ? controller.tailwindColors.teal[500] : controller.tailwindColors.teal[200]).get('rgba')
    ]

    let maxIndex = 0
    if (controller.parsedGraphData.data?.length) {
      maxIndex = controller.parsedGraphData.data[0].data.length - 1
    }

    const borderRadius = 5

    const series = controller.parsedGraphData.data.map((seriesData, i) => {
      // adding pattern to the last data point (since it is the current week)
      seriesData.data[maxIndex] = {
        ...seriesData.data[maxIndex],
        color: {
          pattern: {
            path: 'M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2',
            width: 4,
            height: 4,
            color: colors[i]
          }
        }
      }

      return seriesData
    })

    this.addNoDataSeriesToSeries(series)

    let max = 0
    // if the total is 0, then add it to the zeroes series for min point height
    for (let i = 0; i < series[0].data.length; i++) {
      const total = series[0].data[i].y + series[1].data[i].y
      if (total === 0) {
        series[2].data.push({
          x: series[0].data[i].x,
          y: 0 // 0 to trigger min point length
        })
      } else {
        max = Math.max(max, total)
      }
    }

    // add border radius to the did not meet series (index 0)
    series[0].borderRadiusTopLeft = borderRadius
    series[0].borderRadiusTopRight = borderRadius

    return {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingTop: 0,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0
      },
      ...this.baseConfigOptions,
      xAxis: {
        title: { text: null },
        tickLength: 0,
        labels: {
          enabled: false
        },
        gridLineColor: 'transparent',
        lineWidth: 0,
        maxPadding: 0
      },
      yAxis: {
        title: { text: null },
        lineWidth: 0,
        gridLineWidth: 0,
        labels: {
          enabled: false
        },
        endOnTick: false,
        max: max || 5
      },
      plotOptions: {
        column: {
          crisp: true,
          pointPadding: 0.05,
          groupPadding: 0,
          borderWidth: 0,
          connectNulls: true,
          dataLabels: {
            enabled: false
          },
          stacking: 'normal'
        },
        series: {
          states: {
            hover: {
              brightness: -0.1,
              saturation: 0.2
            }
          },
          dataLabels: {
            enabled: false
          },
          cursor: 'pointer',
          stickyTracking: false,
          trackByArea: true,
          point: {
            events: {
              click: function () {
                if (controller.optionsValue.metrics_url) {
                  controller.openTurboStream(controller.optionsValue.metrics_url, {
                    breakdown_date: new Date(this.x).toISOString().split('T')[0],
                    git_user_group_id: controller.optionsValue.git_user_group_id,
                    repository_names: controller.optionsValue.repository_names,
                    stage: controller.optionsValue.goal_type
                  })
                }
              }
            }
          }
        }
      },
      colors,
      series,
      tooltip: {
        ...controller.baseToolTipConfig,
        shared: true,
        positioner: function (w, h, point) {
          let tooltipX = point.plotX
          // if the tooltip is going out of the chart, then move it to the left, otherwise keep the orig plotX position
          // use point.plotX instead of this.chart.hoverPoint.plotX as hoverPoint is not always available
          if (tooltipX + w > this.chart.plotWidth) {
            tooltipX = this.chart.plotWidth - w
          } else {
            const offset = point.plotX - w / 3
            if (offset < 0) {
              tooltipX = 0
            } else {
              tooltipX = offset
            }
          }
          return {
            x: tooltipX,
            y: -h // short graphs, so tooltip on top
          }
        },
        formatter: function () {
          const startOfWeek = new Date(this.x)
          const endOfWeek = startOfWeek.getTime() + (24 * 60 * 60 * 1000 * 6)
          const date = `${Highcharts.dateFormat('%b %e', startOfWeek)} - ${Highcharts.dateFormat('%b %e', endOfWeek)}`

          const breakDownValues = this.points.map((p, i) => {
            if (p.series.name === 'zeroes') return ''

            return breakDownValue({
              name: p.series.name,
              value: `${p.y} <span class="text-gray-600 dark:text-gray-400">(${Math.round(p.percentage || 0)}%)</span>`,
              style: `background-color: ${colors[p.colorIndex]};`,
              type: 'box'
            })
          }).reverse().filter(p => !!p)

          const countPrs = this.points[0].total

          return card({
            date,
            incomplete: this.points[0].point.index === maxIndex,
            primaryValues: [
              primaryValue({ name: 'Total pull requests', value: countPrs })
            ],
            breakDownValues
          })
        }
      }
    }
  }

  openSidebar (e) {
    this.openTurboStream(this.optionsValue.metrics_url, {
      breakdown_date: new Date(Number(e.target.dataset.value)).toISOString().split('T')[0],
      git_user_group_id: this.optionsValue.git_user_group_id,
      repository_names: this.optionsValue.repository_names,
      stage: this.optionsValue.goal_type
    })
  }
}
